<template>
    <div class="modal fade" id="sales_person_detail" tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title custom-color" id="exampleModalCenterTitle">Salesman Detail</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body text-left">

                    <div class="row">
                        <div class="col-sm-12">
                            <table class="table table-striped" v-if="salesman!=null">
                                <tr>
                                    <td>Name</td>
                                    <td>{{salesman.full_name}}</td>
                                </tr>
                                <tr>
                                    <td>Username</td>
                                    <td>{{salesman.user_name}}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{{salesman.email}}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{{salesman.salesman.phone}}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{{salesman.salesman.address}}</td>
                                </tr>
                                <tr>
                                    <td colspan="2" class="text-danger">
                                        {{salesman.salesman.all_orders ? 'This salesman can see all orders' : 'This salesman can see orders which are assigned to him'}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-12 ">
                            <button type="button" class="btn float-right btn-outline-info btn-sm btn-wide"
                                    data-dismiss="modal">Close
                            </button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

</template>
<script>

    export default{
        props: ['salesman'],
        data(){
            return {}
        }, methods: {},
        mounted(){
        }
    }
</script>